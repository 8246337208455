import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/colebemis.com/colebemis.com/src/templates/note-template.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ol>
      <li parentName="ol">
        <p parentName="li">{`Install `}<a parentName="p" {...{
            "href": "https://github.com/apps/changeset-bot"
          }}>{`changeset-bot`}</a>{` on your repository`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Install `}<a parentName="p" {...{
            "href": "https://github.com/atlassian/changesets/tree/master/packages/cli"
          }}><inlineCode parentName="a">{`@changesets/cli`}</inlineCode></a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`yarn add --dev @changesets/cli
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Initialize changesets`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`yarn changeset init
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add a `}<inlineCode parentName="p">{`release`}</inlineCode>{` script`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-diff"
          }}>{`// package.json
{
  "scripts": {
    "release": "yarn build && changeset publish"
  }
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Generate an `}<a parentName="p" {...{
            "href": "https://docs.npmjs.com/creating-and-viewing-authentication-tokens"
          }}>{`npm token`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add an `}<inlineCode parentName="p">{`NPM_TOKEN`}</inlineCode>{` `}<a parentName="p" {...{
            "href": "https://docs.github.com/en/free-pro-team@latest/actions/reference/encrypted-secrets#creating-encrypted-secrets-for-a-repository"
          }}>{`secret`}</a>{` for your repository`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Create `}<a parentName="p" {...{
            "href": "https://github.com/changesets/action#with-publishing"
          }}><inlineCode parentName="a">{`.github/workflows/release.yml`}</inlineCode></a></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`(Optional) Add `}<a parentName="p" {...{
            "href": "https://github.com/atlassian/changesets/blob/master/docs/snapshot-releases.md"
          }}>{`snapshot releases`}</a>{` to your CI workflow`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-yml"
          }}>{`# .github/workflows/ci.yml
name: CI
on:
  push:
    branches-ignore:
      - $default-branch
jobs:
  ci:
    name: CI
    runs-on: ubuntu-latest
    steps:
      - name: Checkout repo
        uses: actions/checkout@master
        with:
          # This makes Actions fetch all Git history so that Changesets
          # can generate changelogs with the correct commits.
          fetch-depth: 0

      # If you use a version of Node other than 12, change it here.
      - name: Set up Node.js 12.x
        uses: actions/setup-node@master
        with:
          node-version: 12.x

      # If you use npm instead of yarn, change this to \`npm install\`.
      - name: Install dependencies
        run: yarn

      # Change this to customize your build command.
      - name: Build
        run: yarn build

      # Change this to customize your test command.
      - name: Test
        run: yarn test

      - name: Create .npmrc
        run: |
          cat << EOF > "$HOME/.npmrc"
            //registry.npmjs.org/:_authToken=$NPM_TOKEN
          EOF
        env:
          NPM_TOKEN: \${{ secrets.NPM_TOKEN }}

      - name: Release snapshot
        run: |
          npx changeset version --snapshot
          npx changeset publish --tag canary
        env:
          GITHUB_TOKEN: \${{ secrets.GITHUB_TOKEN }}

      - name: Post published version to GitHub checks
        run: |
          name=$(jq -r .name package.json)
          version=$(jq -r .version package.json)
          npx action-status --context=$name --state=success --description=$version --url="https://unpkg.com/$name@$version/"
        env:
          GITHUB_TOKEN: \${{ secrets.GITHUB_TOKEN }}
`}</code></pre>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      